import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Container from '../components/container'
import { Helmet } from 'react-helmet'

export default function SuccessPage({ state }) {
    const navigation = [
		{ name: 'На головну', href: '/', key: 51 },
	]

    return (
        <div>
            <Helmet>
				<meta charSet="utf-8" />
				<meta name="description" content="Виробник найкращої бакалійної продукції під власною торговою маркою «Дем'янович»" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
				<meta name="image" content="bg.jpg" />
				<meta name="theme-color" content="#53C82A"/>
                <link rel="apple-touch-icon" href="logo.png"></link>
				<title>Дем'янович</title>
			</Helmet>
            <div className="bg-hero-image bg-no-repeat bg-cover bg-top overflow-hidden">
                <Container>
                    <Navbar navigation={navigation} />
                </Container>
            </div>

            <Container>
                <div className="flex flex-col justify-center items-center h-success-screen">
                    <h1 className="text-4xl tracking-tight font-bold sm:text-5xl md:text-6xl">
                        Дякую!
                    </h1>
                    <p className="mt-3 text-center text-base font-light sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Ваша заявка успішно відправлена!  
					</p>
                </div>
            </Container>

            <div className="bg-green-400">
                <Container>
                    <Footer />
                </Container>
            </div>
        </div>
    )
}
